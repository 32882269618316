import React from 'react';

// Components.
import StyledSvg from '../StyledSvg';

export interface IProps {
  color?: string;
  hoverColor?: string;
  size?: string;
}

export const TwitterIconSvg: React.FC<IProps> = ({
  color,
  hoverColor,
  size,
}: IProps) => (
  <StyledSvg
    color={color}
    hoverColor={hoverColor}
    size={size}
    viewBox="0 0 512 510"
  >
    <path d="m 33,464 v -9 H 16.5 0 V 436.5 418 h 49 49 v -5 -5 h 18.5 18.5 v -12 -12 h 11 11 V 373.5 363 H 116.94285 76.885694 L 77.192847,350.49818 77.5,337.99636 64.75,337.99818 52,338 V 318.5 299 H 67.5 83 V 279 259 H 65.43946 47.878919 L 48.18946,247.52057 48.5,236.04114 31.5,235.77057 14.5,235.5 14.229295,215.25 13.958589,195 39.729295,195.00083 65.5,195.00166 V 184.00232 173.00298 L 51.75,173.00198 38,173 38.0013,155.75 38.0026,138.5 21.001316,138.79559 4,139.09119 V 108 76.908812 L 21.001283,77.204406 38.002567,77.5 38.001283,60.25 38,43 H 57 76 L 75.9985,60.25 75.997,77.5 90.998504,77.199891 106,76.899782 V 89.949891 103 h 22.59653 22.59653 l -0.34653,9.50077 -0.34653,9.50078 7.75,-7.8e-4 L 166,122 v 11 11 h 27 27 v 14.5 14.5 h 8 8 V 117.5 62 h 10 10 V 45 28 l 18.75,0.0016 18.75,0.0016 L 293.19729,14.001619 292.89458,0 H 348.44729 404 v 14 14 h 17 17 V 45 62 H 451.5 465 V 52 42 h 19.5 19.5 v 2.5 c 0,2.25 0.4,2.5 4,2.5 h 4 v 6 6 h -4 c -3.77778,0 -4,0.166667 -4,3 v 3 h -8.5 -8.5 v 11.560188 11.560188 l -8.75,-0.192326 c -4.8125,-0.105779 -8.8625,0.09214 -9,0.439812 C 469.1125,88.715538 469,92.6 469,97 v 8 h 21.5 21.5 v 16 16 H 499.5 487 v 12 12 h -8.5 -8.5 v 29.5 29.5 h -6.5 -6.5 v 31.05559 31.0556 l -13.00175,-0.3056 -13.00175,-0.30559 0.002,23.75 0.002,23.75 h -13.5 -13.5 v 23 23 h -13 -13 v 16 16 h -20 -20 v 13 13 h -22.5 -22.5 v 8.5 8.5 h -24.5 -24.5 v 11.5 11.5 h -105.5 -105.5 v -9 z" />
  </StyledSvg>
);

export default TwitterIconSvg;
